//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import stateList from "../../assets/src/usstates.json";

export default {
  data() {
    return {
      jobSearch: {
        // query: "",
        // type: "allTypes",
        state: "",
        city: "",
        page: 1
      },
      jobTypes: ["Hourly", "Management", "Support Center"]
    };
  },
  computed: {
    ...mapState(["allLocations", "jobs"]),
    stateList() {
      const stateDict = Object.fromEntries(stateList);
      const stateAbbreviations = [
        ...new Set(this.allLocations.map(location => location.state))
      ].sort();

      const stateObjectsArray = stateAbbreviations.map(abbreviatedName => ({
        abbreviatedName,
        fullName: stateDict[abbreviatedName]
      }));

      return stateObjectsArray;
    },
    citiesBySelectedState() {
      const { state } = this.jobSearch;

      if (!state || state === "allStates") {
        return [
          ...new Set(this.allLocations.map(location => location.city))
        ].sort();
      }

      const cities = [
        ...new Set(
          this.allLocations
            .filter(location => location.state === state)
            .map(location => location.city)
        )
      ].sort();

      return cities;
    }
  },
  created() {
    if (Object.keys(this.$route.query).length) {
      this.jobSearch = { state: "", city: "", ...this.$route.query, page: 1 };
    }
  },
  methods: {
    async searchJobs() {
      const jobToSearchQuery = {
        ...this.jobSearch,
        page: parseInt(this.jobSearch.page)
      };

      for (const key in jobToSearchQuery) {
        if (jobToSearchQuery.hasOwnProperty(key) && !jobToSearchQuery[key]) {
          delete jobToSearchQuery[key];
        }
      }

      this.$router.push({ path: "/apply", query: { ...jobToSearchQuery } });
    }
  }
};
