//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueSlickCarousel from "vue-slick-carousel";

export default {
  components: {
    VueSlickCarousel
  },
  props: {
    blok: {
      type: Object,
      default: () => ({
        slides: []
      })
    }
  },
  data() {
    return {
      loaded: false
    };
  },
  computed: {
    mq() {
      return this.$mq;
    },
    isOneSlide() {
      return this.blok.slides.length === 1;
    },
    carouselSettings() {
      return {
        arrows: !this.isOneSlide,
        dots: !this.isOneSlide,
        slidesToShow: 1,
        autoplay: !this.isOneSlide,
        swipe: !this.isOneSlide,
        draggable: !this.isOneSlide,
        autoplaySpeed: 6000
        // speed: 0
      };
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.loaded = true;

      this.equalizeSlideHeights();

      window.addEventListener("resize", this.equalizeSlideHeights);
      window.addEventListener("orientationchange", this.equalizeSlideHeights);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.equalizeSlideHeights);
    window.removeEventListener("orientationchange", this.equalizeSlideHeights);
  },
  methods: {
    next() {
      this.$refs.carousel.next();
    },
    previous() {
      this.$refs.carousel.prev();
    },
    equalizeSlideHeights() {
      if (this.isOneSlide) {
        return;
      }

      this.$nextTick(() => {
        let maxHeight = 0;
        const carousel = this.$refs.carousel;
        if (!carousel) {
          return;
        }

        const slides = carousel.$el.querySelectorAll(".hero-slides");
        if (!slides.length) {
          return;
        }

        // Reset all heights
        slides.forEach(slide => {
          slide.style.height = "auto";
        });

        // Find max height
        slides.forEach(slide => {
          if (slide.offsetHeight > maxHeight) {
            maxHeight = slide.offsetHeight;
          }
        });

        // Set max height to all slides
        slides.forEach(slide => {
          slide.style.height = maxHeight + "px";
        });
      });
    }
  }
};
