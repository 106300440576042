//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    slide: {
      type: Object,
      default: () => {}
    },
    isOneSlide: {
      type: Boolean,
      default: false
    },
    isFirstSlide: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasVideo() {
      return (
        this.slide.background_video && this.slide.background_video.filename
      );
    },
    isImageOrVideoOnly() {
      const hasImageOrVideo =
        this.hasVideo ||
        (this.slide.background_image && this.slide.background_image.filename);
      return (
        hasImageOrVideo &&
        !this.slide.tagline &&
        !this.slide.heading &&
        !this.slide.description &&
        !this.slide.button
      );
    },
    imageMobileClasses() {
      return [
        {
          "md:!hidden":
            this.slide.background_image && this.slide.background_image.filename
        },
        { "my-auto sm:my-0 ": this.isImageOrVideoOnly }
      ];
    },
    imageDesktopClasses() {
      return [
        {
          "!hidden md:!block":
            this.slide.background_image_mobile &&
            this.slide.background_image_mobile.filename
        },
        { "my-auto sm:my-0 md:max-w-full": this.isImageOrVideoOnly },
        this.isOneSlide ? "md:h-[460px]" : "md:min-h-[460px]"
      ];
    }
  }
};
